@font-face {
  font-family: "OmegaIcons";
  src: url("../fonts/OmegaIcons.eot?5dtbkv");
  src: url("../fonts/OmegaIcons.eot?5dtbkv#iefix") format("embedded-opentype"),
  url("../fonts/OmegaIcons.ttf?5dtbkv") format("truetype"), url("../fonts/OmegaIcons.woff?5dtbkv") format("woff"),
  url("../fonts/OmegaIcons.svg?5dtbkv#OmegaIcons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
